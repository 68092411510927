import React from "react"
import Accordian from "../../core/components/accordian"
import Block from "../../core/components/block"
import Banner from "../../client/compositions/banner"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ContactUs from "../../client/compositions/contact-us"
import Row from "../../core/components/row"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Warranty Page
 * @see https://zpl.io/a7AWyPp
 */
function WarrantyPage() {
  return (
    <article id="page-warranty" className="page-content">
      {quickMenu}

      <Banner title={<>Warranty</>} />

      <Row id="warranty" className="long-text">
        <Block className="block-content">
          <h2 className="title">Warranty</h2>
        </Block>
        <Block className="block-content">
          <h4 className="title">Limited Lifetime Warranty</h4>
          <p>
            <em>
              The warranty obligations of InVinity for this product are limited
              to the terms set forth below:
            </em>
          </p>
          <Accordian
            label={
              <>
                <Decoration image="icons/accordian-open.svg" className="open" />
                <Decoration
                  image="icons/accordian-close.svg"
                  className="close"
                />
                <h4 className="title">What is covered?</h4>
              </>
            }
          >
            This limited warranty covers defects in materials and workmanship in
            this product.
          </Accordian>
          <h4 className="title">Please Note</h4>
          <p>
            We do not accept any returns of InVinity products not purchased
            directly from our site. If you wish to return an InVinity product
            purchased from another retailer, you must contact them directly.
          </p>
        </Block>
      </Row>

      <ContactUs>
        <DecorationFluid image="/terms/contact.small.jpg" />
      </ContactUs>
      <Tagline />
    </article>
  )
}

export default WarrantyPage
